import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'
import { isSignedIn } from '../helpers'
import { logoutUser } from '../store/actions/auth'
import TalentpoolConfirmation from '../components/TalentpoolConfirmation/TalentpoolConfirmation'
import TalentpoolOrganisations from '../components/TalentpoolOrganisations/TalentpoolOrganisations'
import TalentpoolDeclinedPanel from '../components/TalentpoolDecilnedPanel/TalentpoolDeclinedPanel'
import { newCallApi } from '../store/actions/api'
import history from '../history'

class Talentpool extends Component {
    constructor(props) {
        super(props)

        // If the user is not authenticated, log them out
        if (!isSignedIn(this.props.auth)) {
            this.props.dispatch(logoutUser(this.props.auth, null))
        }

        this.state = {
            decisionMade: null,
            acceptedInvitation: null,
        }
    }

    componentDidMount() {
        // If the user is not invited, redirect them to the main page
        if (this.props.user.profile.talentpool_invitation_sent == 0) {
            history.push('/')
        }

        // Set the accepted invitation based on if the user has joined
        this.setState({
            acceptedInvitation:
                this.props.user.profile.talentpool_joined != null &&
                this.props.user.profile.talentpool_joined,
        })

        // Set the decision made based on if a decision was made
        this.setState({ decisionMade: this.props.user.profile.talentpool_joined != null })
    }

    handleAccept = () => {
        newCallApi('POST', this.props.config.API_TALENTPOOL, {
            join: true,
            talentpool_organisation_preference: 'none',
        })
            .then(() => {})
            .catch((error) => {
                console.log('Got error', error)
            })
            .then(() => {
                this.setState({ acceptedInvitation: true, decisionMade: true })
            })
    }

    handleDecline = () => {
        newCallApi('POST', this.props.config.API_TALENTPOOL, {
            join: false,
        })
            .then(() => {})
            .catch((error) => {
                console.log('Got error', error)
            })
            .then(() => {
                this.setState({ acceptedInvitation: false, decisionMade: true })
            })
    }

    handleLeaveTalentpool = () => {
        newCallApi('POST', this.props.config.API_TALENTPOOL, {
            join: false,
        })
            .then(() => {})
            .catch((error) => {
                console.log('Got error', error)
            })
            .then(() => {
                this.setState({ acceptedInvitation: false, decisionMade: true })
            })
    }

    render() {
        return (
            <div>
                {!this.state.decisionMade && (
                    <TalentpoolConfirmation
                        headerText={translate('talentpool.invitation_title')}
                        bodyText={translate('talentpool.invitation_body')}
                        acceptLabel={translate('talentpool.invitation_accept')}
                        declineLabel={translate('talentpool.invitation_decline')}
                        onAccept={this.handleAccept}
                        onDecline={this.handleDecline}
                    />
                )}

                {!this.state.decisionMade || this.state.acceptedInvitation ? (
                    <TalentpoolOrganisations
                        headerText={translate('talentpool.organisations_title')}
                        bodyText={translate('talentpool.organisations_body')}
                        disabled={!this.state.acceptedInvitation}
                        onLeave={this.handleLeaveTalentpool}
                    />
                ) : null}

                {this.state.decisionMade && !this.state.acceptedInvitation && (
                    <TalentpoolDeclinedPanel />
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state
    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Talentpool)
