import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { translate, Translate } from 'react-i18nify'
import Panel from '../Panel/Panel'
import ButtonPanel from '../Button/ButtonPanel'
import ModalForm from '../Modal/ModalForm'
import OrganisationsGenerator from '../Forms/OrganisationsGenerator'
import Tag from '../Tag/Tag'
import classNames from 'classnames'
import { newCallApi } from '../../store/actions/api'
import { connect } from 'react-redux'

class TalentpoolOrganisations extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addOrganisation: false,
            organisations: [],
            selectedOrganisations: props.user.profile.talentpool_organisations,
            expanded: false,
        }
    }

    componentDidMount() {
        // Set all organisations
        newCallApi('GET', this.props.config.API_ORGANIZATIONS, null)
            .then((resp) => {
                this.setState({ organisations: resp })
            })
            .catch((error) => {
                console.log('Got error', error)
            })

        // Set the user's organisations
        this.setState()
    }

    handleSave = (organisations, preference) => {
        let payload = {}
        if (preference == 'custom') {
            let organisationIds = organisations.map((organisation) => organisation.id)
            payload = {
                join: true,
                talentpool_organisation_preference: preference,
                organisations: organisationIds,
            }
        } else {
            payload = {
                join: true,
                talentpool_organisation_preference: preference,
            }
        }

        newCallApi('POST', this.props.config.API_TALENTPOOL, payload)
            .then(() => {})
            .catch((error) => {
                console.log('Got error', error)
            })
            .then(() => {
                this.setState({ selectedOrganisations: organisations, addOrganisation: false })
            })
    }

    removeOrganisation = (organisation) => {
        // Set the organisations in the state
        let selectedOrganisations = this.state.selectedOrganisations
        if (selectedOrganisations.indexOf(organisation) > -1) {
            selectedOrganisations.splice(selectedOrganisations.indexOf(organisation), 1)

            let payload = {}
            if (
                organisation.id == this.props.user.profile.organisation_id &&
                selectedOrganisations.length == this.state.organisations.length - 1
            ) {
                payload = {
                    join: true,
                    talentpool_organisation_preference: 'all_except_current',
                }
            } else {
                let organisationIds = selectedOrganisations.map((organisation) => organisation.id)
                payload = {
                    join: true,
                    talentpool_organisation_preference: 'custom',
                    organisations: organisationIds,
                }
            }

            newCallApi('POST', this.props.config.API_TALENTPOOL, payload)
                .then(() => {})
                .catch((error) => {
                    console.log('Got error', error)
                })
                .then(() => {
                    this.setState({
                        selectedOrganisations: selectedOrganisations,
                    })
                })
        }
    }

    removeAllOrganisations = () => {
        newCallApi('POST', this.props.config.API_TALENTPOOL, {
            join: true,
            talentpool_organisation_preference: 'none',
        })
            .then(() => {})
            .catch((error) => {
                console.log('Got error', error)
            })
            .then(() => {
                this.setState({
                    selectedOrganisations: [],
                })
            })
    }

    leaveTalentpool() {
        let pro = window.confirm('Weet u zeker dat u wilt vertrekken?')
        if (pro) {
            this.props.onLeave()
        }
    }

    render() {
        const { headerText, bodyText, disabled } = this.props
        const canExpand =
            this.state.selectedOrganisations && this.state.selectedOrganisations.length > 5
        const panelClassname = classNames(
            `panel--confirmation talentpool-organisations ${disabled ? 'disabled' : ''}`,
            {
                'panel--expand tags': canExpand && !this.state.expanded,
            },
        )

        const addButton =
            this.state.selectedOrganisations && this.state.selectedOrganisations.length > 0 ? (
                <span>
                    <div className="header-buttons">
                        <div
                            className="remove-button"
                            onClick={() => {
                                this.removeAllOrganisations()
                            }}
                        >
                            <Translate value="talentpool.remove_all" />
                        </div>
                        <ButtonPanel
                            buttonText={'Toevoegen'}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addOrganisation: true,
                                })
                            }}
                        />
                    </div>
                    <ButtonPanel
                        buttonText={'Toevoegen'}
                        className="button--cta hide-md"
                        onClick={() => {
                            this.setState({
                                addOrganisation: true,
                            })
                        }}
                    />
                </span>
            ) : null
        return (
            <Container>
                <Row>
                    <Col>
                        <Panel
                            className={panelClassname}
                            addButton={addButton}
                            headerText={headerText}
                            headerIcon="icon-folder"
                        >
                            {this.state.selectedOrganisations &&
                            this.state.selectedOrganisations.length > 0 ? (
                                <div className="panel__form">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="panel__tags">
                                                {this.state.selectedOrganisations.map(
                                                    (organisation) => {
                                                        return (
                                                            <Tag
                                                                key={organisation.id}
                                                                score={1}
                                                                onRemove={() => {
                                                                    this.removeOrganisation(
                                                                        organisation,
                                                                    )
                                                                }}
                                                                showEdit={false}
                                                                title={organisation.name}
                                                            >
                                                                {organisation.name}
                                                            </Tag>
                                                        )
                                                    },
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <p>{bodyText}</p>
                            )}
                            {this.state.selectedOrganisations &&
                                this.state.selectedOrganisations.length == 0 && (
                                    <div className="button__container">
                                        <ButtonPanel
                                            buttonText={translate(
                                                'talentpool.organisations_button',
                                            )}
                                            className="button--cta"
                                            onClick={() => {
                                                this.setState({
                                                    addOrganisation: true,
                                                })
                                            }}
                                        />
                                    </div>
                                )}

                            {this.state.addOrganisation && (
                                <ModalForm
                                    title={translate('talentpool.organisations_button')}
                                    show
                                    onClose={() => {
                                        this.setState({
                                            addOrganisation: false,
                                        })
                                    }}
                                >
                                    <OrganisationsGenerator
                                        organisations={this.state.organisations}
                                        onSave={this.handleSave}
                                        selectedOrganisations={this.state.selectedOrganisations}
                                        userOrganisationId={this.props.user.profile.organisation_id}
                                    />
                                </ModalForm>
                            )}
                        </Panel>
                    </Col>
                </Row>
                {this.state.selectedOrganisations && this.state.selectedOrganisations.length > 0 && (
                    <Row>
                        <div className="leave-button-container">
                            <div
                                className="button--secondary"
                                onClick={() => {
                                    this.leaveTalentpool()
                                }}
                            >
                                <Translate value="talentpool.leave" />
                            </div>
                        </div>
                    </Row>
                )}
            </Container>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state
    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(TalentpoolOrganisations)
