import React, { Component } from 'react'
import { Container, Hidden, Row } from 'react-grid-system'
import { translate } from 'react-i18nify'
import {
    editProfileItem,
    getJobs,
    readCVFile,
    fetchCVData,
    diffProfileItems,
} from '../../store/actions/user'
import GridCol from '../GridCol/GridCol'
import GridColOffset from '../GridCol/GridColOffset'
import Intro from './Steps/Intro'
import HighestStudy from './Steps/HighestStudy'
import CvUpload from './Steps/CvUpload'
import Documents from './Steps/Documents'
import Discovery from './Steps/Discovery'
import Outro from './Steps/Outro'
import PersonalInfo from './Steps/PersonalInfo'
import PersonalInformationLateral from './Steps/LateralSteps/PersonalInformationLateral'
import FlexAppStep from './Steps/FlexAppStep'
import CreationOptions from './Steps/CreationOptions'
import { connect } from 'react-redux'
import { ACCOUNT_ROLES, PATH_PROFILE, LATERAL_CREATION_OPTIONS } from '../../constants/Constants'
import history from '../../history'
import newCallApi from '../../store/actions/api'
import { getDocumentsUrl } from '../../store/actions/user'

class Onboarding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            onboardingStep: 0,
            states: {},
            jobs: [],
            scannedCv: null,
            fileError: false, // file is not CV
            stopLoading: false, // stop the loading in CvUpload
            isFirstTime: true, // user is filling out the diploma form for the first time
            diplomaId: -1, // id of the diploma in db, determines create or update
            jobIds: [], // to update the jobs
            uploadedCv: null,
        }
        this.nextStep = this.nextStep.bind(this)
        this.prevStep = this.prevStep.bind(this)
        this.finishOnboarding = this.finishOnboarding.bind(this)
        this.saveCache = this.saveCache.bind(this)

        // Fetch a pre-existing CV uploaded by a Talentpool invitation
        let url = getDocumentsUrl(this.props.user.profile.id || this.props.auth.id)

        newCallApi('GET', url, null, props.auth).then(
            (json) => {
                if (json && json.length) {
                    let fileUrl = getDocumentsUrl(
                        this.props.user.profile.id || this.props.auth.id,
                        json[0].id,
                    )
                    newCallApi('GET', fileUrl, null, /* auth */ null, true, true, 'blob').then(
                        (blob) => {
                            let cv = new File([blob], json[0].name)
                            this.state.uploadedCv = cv
                        },
                    )
                }
            },
            () => {},
        )
    }

    // todo: refactor how onboarding works, mount all steps and just show the one you need and move the
    // api dependencies to the components
    componentDidMount() {
        this.getJobs()
    }

    getJobs() {
        getJobs(this.props.auth, false, (response) => {
            this.setState({
                jobs: response,
            })
        })
    }

    nextStep() {
        if (this.state.onboardingStep + 1 === this.getOnboardingSteps().length) {
            this.finishOnboarding()
            return
        }

        this.setState({ onboardingStep: this.state.onboardingStep + 1 })
    }

    prevStep() {
        this.setState({ onboardingStep: this.state.onboardingStep - 1 })
    }

    saveCache(key, cache) {
        this.setState(({ states }) => ({
            states: { ...states, [key]: cache },
        }))
    }

    finishOnboarding() {
        const { accTypes } = this.props

        this.props.dispatch(
            editProfileItem(
                'general',
                { onboarding_done: true },
                this.props.auth,
                () => {
                    if (!accTypes.includes(ACCOUNT_ROLES.PLANNER)) {
                        setTimeout(history.push(PATH_PROFILE + '?onboarding=true&number=2'), 2000)
                    }
                },
                () => {},
            ),
        )
    }

    isFlexEmployee() {
        const { user } = this.props
        return user.profile.account_types.find((type) => type.code === 'flexapp-employee')
    }

    shouldSeeFlexStep() {
        const {
            config: {
                whitelabel: { flexapp },
            },
        } = this.props
        return flexapp && flexapp.used && this.isFlexEmployee()
    }

    shouldSeeLateralSpecificSteps() {
        const { user, config } = this.props
        const { onboarding_lateral_entrants } = config.whitelabel

        return (
            user?.profile?.creation_option &&
            onboarding_lateral_entrants &&
            LATERAL_CREATION_OPTIONS.includes(user.profile.creation_option)
        )
    }

    resetCVError() {
        this.setState({ fileError: false })
    }

    resetStopLoading() {
        this.setState({ stopLoading: false })
    }

    // Diploma form bas been filled out
    diplomaFilled = (newId) => {
        this.setState({ diplomaId: newId, isFirstTime: false })
    }

    getCvDataAndProceed(file) {
        if (file == null) {
            // If no file, then we are manually filling out our study info
            this.setState({ scannedStudy: null })
        } else {
            const auth = {
                isAuthenticated: true,
                token: this.props.config.whitelabel.cv_scanner_token,
            }
            readCVFile(file, auth)
                .then((_id) => {
                    const fileId = _id
                    fetchCVData(fileId, auth)
                        .then((content) => {
                            if (!content.is_cv) {
                                // if the file is not a CV, give en error message
                                this.setState({ fileError: true, stopLoading: true })
                                return
                            }
                            // get work experience
                            const scannedJobs = content.work_experience
                            const jobs = scannedJobs.map((job) => {
                                let startDate = null
                                let endDate = null
                                if (job.start_date != null && job.start_date.length > 0) {
                                    startDate = job.start_date
                                    if (startDate.split('-').length < 3) {
                                        startDate += '-01'
                                    }
                                }
                                if (job.end_date != null && job.end_date.length > 0) {
                                    endDate = job.end_date
                                    if (endDate.split('-').length < 3) {
                                        endDate += '-01'
                                    }
                                }
                                return {
                                    function_title: job.function,
                                    company_name: job.company,
                                    city: job.company_location || '-',
                                    start_date: startDate,
                                    end_date: endDate,
                                    current_position: job.is_current_job,
                                    job_working_area_id: 9, // cv scanner endpoint response doesn't provide related information, so set to 'others'
                                }
                            })
                            this.props
                                .dispatch(
                                    diffProfileItems(
                                        'job_functions',
                                        jobs, // add the scanned jobs
                                        this.state.jobIds, // delete the previously scanned jobs
                                        this.props.auth,
                                    ),
                                )
                                .then((ids) => {
                                    this.setState({ jobIds: ids })
                                })
                            // get the highest study
                            const education = content.educational_background.reduce(
                                (highest, current) =>
                                    new Date(current.end_date) > new Date(highest.end_date)
                                        ? current
                                        : highest,
                                content.educational_background[0],
                            )
                            this.setState(
                                {
                                    scannedStudy: {
                                        start_date: education.start_date,
                                        end_date: education.end_date,
                                        institute_name: education.institution,
                                        finished: education.diploma_acquired,
                                        study_id: this.getStudyId(
                                            education.name_of_study,
                                            this.props.studies,
                                        ),
                                        study_name_other: this.getOtherStudyName(
                                            education.name_of_study,
                                            this.props.studies,
                                        ),
                                        study_level_id: this.getStudyLevelId(
                                            education.level_of_education,
                                            this.props.study_levels,
                                        ),
                                    },
                                },
                                () => {
                                    this.nextStep()
                                },
                            )
                        })
                        .catch((error) => {
                            console.error('Error fetching CV data:', error)
                        })
                })
                .catch((error) => {
                    console.error('Reading CV error:', error)
                })
        }
    }

    getStudyLevelId(name, study_levels) {
        if (!name) return null
        // ignore the case
        const lowerCaseName = name.toLowerCase()
        const level = study_levels.find((level) => {
            const levelName = level.name || ''
            return lowerCaseName.includes(levelName.toLowerCase())
        })
        return level ? level.id : null
    }

    getStudyId(name, studies) {
        // If no match, return the study id of 'Overig' (182)
        if (!name) return 182
        const study = studies.find((study) => study.name == name)
        return study ? study.id : 182
    }

    getUploadedCv() {
        return this.state.uploadedCv
    }

    getOtherStudyName(name, studies) {
        if (!name) return null
        const study = studies.find((study) => study.name == name)
        return study ? null : name
    }

    // this.props.config.whitelabel.my_flag ? {}
    getOnboardingSteps() {
        const { accTypes } = this.props
        const { flexapp, onboarding_lateral_entrants } = this.props.config.whitelabel
        const flexUrl = flexapp ? flexapp.url : null
        const stepProps = {
            nextStep: this.nextStep,
            prevStep: this.prevStep,
            auth: this.props.auth,
            user: this.props.user,
            cache: this.state.states,
            saveCache: this.saveCache,
            onboardingStep: this.state.onboardingStep,
            fileError: this.state.fileError,
            stopLoading: this.state.stopLoading,
        }

        let steps = [
            {
                key: 'intro',
                title: translate('onboarding.title1'),
                el: <Intro {...stepProps} />,
                skipFor: [ACCOUNT_ROLES.PLANNER],
                skipWhen: false,
            },
        ]

        // Check if feature flag is enabled
        if (onboarding_lateral_entrants) {
            steps = steps.concat([
                {
                    key: 'creationOptions',
                    title: translate('creation_options.onboarding_title'), //translate('onboarding.title2'),
                    el: <CreationOptions {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                    skipWhen: !onboarding_lateral_entrants,
                },
            ])
        }

        if (this.shouldSeeLateralSpecificSteps()) {
            //Specific steps for lateral entrants
            const lateralSpecificSteps = [
                {
                    key: 'personalInformationLateral',
                    title: translate('onboarding.title2'),
                    el: <PersonalInformationLateral {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'highest_study',
                    title: translate('onboarding.title2'),
                    el: <HighestStudy {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'documents',
                    title: translate('onboarding.title2'),
                    el: <Documents {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'discovery',
                    title: translate('onboarding.title2'),
                    el: <Discovery {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'outro',
                    title:
                        !accTypes.includes(ACCOUNT_ROLES.PLANNER) && translate('onboarding.title1'),
                    el: (
                        <Outro
                            {...stepProps}
                            flexUrl={flexUrl}
                            isFlexEmployee={this.isFlexEmployee()}
                            isPlanner={accTypes.includes(ACCOUNT_ROLES.PLANNER)}
                            isLateral={true}
                        />
                    ),
                    skipFor: [],
                },
            ]
            steps = steps.concat(lateralSpecificSteps)
        } else {
            //Default steps
            const defaultSteps = [
                {
                    key: 'personalInfo',
                    title: translate('onboarding.title2'),
                    el: <PersonalInfo {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'cvUpload',
                    title: translate('onboarding.title2'),
                    el: (
                        <CvUpload
                            {...stepProps}
                            handleCvUpload={(file) => {
                                this.getCvDataAndProceed(file)
                            }}
                            handleErrorMessageReset={() => {
                                this.resetCVError()
                            }}
                            handleStopLoading={() => {
                                this.resetStopLoading()
                            }}
                            getUploadedCv={this.getUploadedCv.bind(this)}
                        />
                    ),
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'highest_study',
                    title: translate('onboarding.title2'),
                    el: (
                        <HighestStudy
                            {...stepProps}
                            scannedStudy={this.state.scannedStudy}
                            diplomaFilled={(newId) => {
                                this.diplomaFilled(newId)
                            }}
                            diplomaId={this.state.diplomaId}
                            isFirstTime={this.state.isFirstTime}
                        />
                    ),
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },

                this.shouldSeeFlexStep() && {
                    key: 'flex_app_step',
                    title: translate('onboarding.title2'),
                    el: <FlexAppStep {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'outro',
                    title:
                        !accTypes.includes(ACCOUNT_ROLES.PLANNER) && translate('onboarding.title5'),
                    el: (
                        <Outro
                            {...stepProps}
                            flexUrl={flexUrl}
                            isFlexEmployee={this.isFlexEmployee()}
                            isPlanner={accTypes.includes(ACCOUNT_ROLES.PLANNER)}
                        />
                    ),
                    skipFor: [],
                },
            ]
            steps = steps.concat(defaultSteps)
        }

        return steps.filter(Boolean).filter((step) => {
            const shouldSkipForRoles = step.skipFor.some((skipRole) => accTypes.includes(skipRole))
            return !shouldSkipForRoles && !step.skipWhen
        })
    }

    render() {
        return (
            <div className={'onboarding__backdrop'}>
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <div className="onboarding">
                                <Hidden xs>
                                    <div className="card--simple__intro">
                                        <span>
                                            {
                                                this.getOnboardingSteps()[this.state.onboardingStep]
                                                    .title
                                            }
                                        </span>
                                    </div>
                                </Hidden>

                                {this.getOnboardingSteps()[this.state.onboardingStep].el}

                                <Hidden xs>
                                    <ul className="bullet_list">
                                        {this.getOnboardingSteps().map((step, idx) => {
                                            return (
                                                <li key={step.key}>
                                                    <span
                                                        className={(
                                                            this.state.onboardingStep === idx &&
                                                            'is-active'
                                                        ).toString()}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Hidden>
                            </div>
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
        accTypes: user.profile.account_types.map((type) => type.code),
        studies: user.studies,
        study_levels: user.study_levels,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Onboarding)
